import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ce5577d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "chartRef",
  style: { width: '100%', height: '500px' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.modalVisible,
      title: "零售提交及校验总览",
      footer: null,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
      width: "70%"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, null, 512)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}