import { ref, reactive, onMounted, computed, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { TableData, SearchParams } from '@/views/SalesReporting/RVSubmission/Dealer/types';
import * as API from '@/API/salesReporting';
import downloadFile from '@/utils/claim/downloadFile';
import { TreeDataItem } from 'ant-design-vue/lib/tree/Tree';
import moment from 'moment';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import { getRegionDealerGroup } from '@/API/salesReporting/claimCheckingReport';
import { findBrandByBu, getDealerByGroupId } from '@/API/salesReporting';
import { getAllParentBu } from '@/API/salesReporting/claimCheckingReport';

type Key = ColumnProps['key'];

const useOEMPrepaymentQualified = () => {
  const store = useStore();
  const dealerCode = computed(() => store.state.user.organization.entityCode);
  const select = ref();
  let defaultBu: string | undefined;
  const searchParams = reactive<SearchParams>({
    bu: '',
    brand: 'All',
    region: undefined,
    year: moment().format('YYYY'),
    qm: [moment().format('M')],
    dealerCodeCofico: '',
    dealerCodeSWT: '',
    dealerName: '',
    dealerGroup: '',
    // dealerCode: '',
    dealerCode: dealerCode.value as string
  });
  const qmOptions: TreeDataItem[] = [
    {
      title: 'Q1',
      value: 'Q1',
      key: 'Q1',
      children: [
        {
          title: '1',
          value: '1',
          key: '1'
        },
        {
          title: '2',
          value: '2',
          key: '2'
        },
        {
          title: '3',
          value: '3',
          key: '3'
        }
      ]
    },
    {
      title: 'Q2',
      value: 'Q2',
      key: 'Q2',
      children: [
        {
          title: '4',
          value: '4',
          key: '4'
        },
        {
          title: '5',
          value: '5',
          key: '5'
        },
        {
          title: '6',
          value: '6',
          key: '6'
        }
      ]
    },
    {
      title: 'Q3',
      value: 'Q3',
      key: 'Q3',
      children: [
        {
          title: '7',
          value: '7',
          key: '7'
        },
        {
          title: '8',
          value: '8',
          key: '8'
        },
        {
          title: '9',
          value: '9',
          key: '9'
        }
      ]
    },
    {
      title: 'Q4',
      value: 'Q4',
      key: 'Q4',
      children: [
        {
          title: '10',
          value: '10',
          key: '10'
        },
        {
          title: '11',
          value: '11',
          key: '11'
        },
        {
          title: '12',
          value: '12',
          key: '12'
        }
      ]
    }
  ];
  const activeTab = ref('unQulified');
  const tableData = ref<TableData[]>([]);

  const columns = [
    {
      title: '经销商编号(Cofico)',
      dataIndex: 'dealerConficoCode',
      width: 150
    },
    {
      title: '经销商编号(SWT)',
      dataIndex: 'dealerSwtCode',
      width: 150
    },
    {
      title: '经销商名称(CN)',
      dataIndex: 'dealerNameCn',
      width: 300
    },
    {
      title: '经销商名称(EN)',
      dataIndex: 'dealerNameEn',
      width: 400
    },
    {
      title: '经销商集团',
      dataIndex: 'dealerGroup',
      width: 200
    },
    {
      title: '经销商所在小区',
      dataIndex: 'subRegion',
      width: 150
    },
    {
      title: '业务类型',
      dataIndex: 'bu',
      width: 150
    },
    {
      title: '年',
      dataIndex: 'year',
      width: 150
    },
    {
      title: '季度',
      dataIndex: 'quarter',
      width: 150
    },
    {
      title: '月',
      dataIndex: 'month',
      width: 150
    },
    {
      title: '零售提交数量',
      dataIndex: 'submitedRetailNo',
      width: 150,
      slots: {
        customRender: 'eligibleRetailNo'
      }
    },
    {
      title: '有效零售数量',
      dataIndex: 'eligibleRetailNo',
      width: 150
    },
    {
      title: '零售校验通过率',
      dataIndex: 'eligibleRetailPassingRate',
      width: 200
    },
    {
      title: '实际零售数量',
      dataIndex: 'actualRetailNo',
      width: 150
    },
    {
      title: '整体零售提交率',
      dataIndex: 'retailSubmissionRate',
      width: 200
    }
  ];
  const currentDealerCodes: string[] = [];
  const isDealer = ref(false);
  const isRegion = store.state.user.organization.entityCode === 'Region';
  const judgeUser = (): Promise<any> => {
    const isDealerGroup = store.state.user.legalEntity.entityCode === 'Dealer Group';
    if (isDealerGroup || isRegion) {
      const groupId = store.state.user.orgid;
      return getDealerByGroupId(groupId).then((res: any) => {
        res.forEach((ele: any) => {
          currentDealerCodes.push(ele.dealerInfo.dealerCode);
        });
      });
    } else {
      isDealer.value = true;
      return Promise.resolve().then(() => {
        currentDealerCodes.push(store.state.user.organization.entityCode);
      });
    }
  };

  const getSelectQm = () => {
    let q = '';
    const m: string[] = [];
    if (searchParams.qm) {
      searchParams.qm.forEach((item) => {
        const index = qmOptions.findIndex((o: TreeDataItem) => o.value === item);
        if (index !== -1) {
          q += ',' + item;
          const mList = qmOptions[index].children;
          if (mList) {
            for (let i = 0; i < mList.length; i++) {
              m.push(mList[i].value);
            }
          }
        } else {
          switch (item) {
            case '1':
            case '2':
            case '3':
              if (q.indexOf('Q1') === -1) q += ',Q1';
              break;
            case '4':
            case '5':
            case '6':
              if (q.indexOf('Q2') === -1) q += ',Q2';
              break;
            case '7':
            case '8':
            case '9':
              if (q.indexOf('Q3') === -1) q += ',Q3';
              break;
            case '10':
            case '11':
            case '12':
              if (q.indexOf('Q4') === -1) q += ',Q4';
              break;
          }
          m.push(item);
        }
      });
    }
    return { q: q.substr(1), m };
  };

  const buList = ref<any[]>([]);

  const dogetDefault = () => {
    const mbIndex = buList.value.findIndex((i: any) => {
      return i.nameEn === 'MBPC';
    });
    const initIndex = mbIndex === -1 ? 0 : mbIndex;
    //console.log(initIndex);
    searchParams.bu = buList.value[initIndex].nameEn;
    defaultBu = buList.value[initIndex].nameEn;
  };

  const isEmpty = ref<any>(true);
  const getBuList = () => {
    getAllParentBu().then((res) => {
      buList.value = res;
      console.log(buList.value);
      if (!isRegion) {
        buList.value = buList.value.filter((bu: any) => {
          return bu.parentId === null;
        });
      }
      buList.value = buList.value.filter((bu: any) => {
        return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
      });

      if (buList.value.length > 0) {
        isEmpty.value = false;
      } else {
        message.error('您没有权限查看此页面！');
      }

      dogetDefault();
      handleBuChange();

      nextTick(() => {
        routeInit();
        doSearch();
      });
    });
  };

  const getSelectBuId = () => {
    const index = buList.value.findIndex((item: any) => {
      return item.nameEn === searchParams.bu;
    });
    const initIndex = index === -1 ? 0 : index;
    return buList.value[initIndex]?.id;
  };

  //let bus = store.state.user.buList;
  // if (!isRegion) {
  //   bus = bus.filter((bu: any) => {
  //     return bu.parentId === null;
  //   });
  // }

  //   return buList.value.filter((bu: any) => {
  //     return bu.nameEn !== "Companycar" && bu.nameEn !== "CPO";
  //   });
  // });

  const brandList = ref<any[]>([]);
  const regionList = ref<any[]>([]);
  const regionOnly = ref<any>(false);
  const qmDis = ref<any>(false);
  const handleBuChange = () => {
    const buId = getSelectBuId();
    console.log(buId);
    searchParams.brand = 'All';
    const regionParams = {
      buId: buId,
      dealerCodes: isRegion ? [] : currentDealerCodes
    };
    getRegionDealerGroup(regionParams).then((res) => {
      searchParams.region = undefined;
      // 北东西南 顺序排序
      const sortArr: any[] = [[], [], [], [], []];
      const maps = {
        n: 0,
        e: 1,
        w: 2,
        s: 3
      };
      res.forEach((region: any) => {
        const firstLetter = (region.regionEn[0] as string).toLocaleLowerCase();
        if (firstLetter in maps) {
          sortArr[maps[firstLetter]].push(region);
        } else {
          sortArr[4].push(region);
        }
      });
      regionList.value = sortArr.flat();
    });
    findBrandByBu({ buIds: [buId] }).then((res) => {
      if (res.length === 0) {
        brandList.value = [];
      } else {
        const bdList = res[0].brandList.filter((bu: any) => {
          return bu.brandNameEn !== 'DENZA';
        });
        bdList.push({ brandNameEn: 'All' });
        brandList.value = bdList;
      }
    });
  };

  const doSearch = () => {
    if (!searchParams.year) {
      message.error('Retail period (Year) and BU must be selected!');
      return;
    }
    const { q, m } = getSelectQm();
    const buId = getSelectBuId();
    const params = {
      month: m,
      bu: searchParams.bu,
      buId,
      brand: searchParams.brand,
      region: searchParams.region,
      year: searchParams.year,
      quarters: q,
      cofico: searchParams.dealerCodeCofico,
      swt: searchParams.dealerCodeSWT,
      cn: searchParams.dealerName,
      group: searchParams.dealerGroup,
      dealerCode: isRegion ? null : searchParams.dealerCode,
      dealerCodes: isRegion ? null : currentDealerCodes
    };
    API.querySubmission(params).then((data) => {
      tableData.value = data;
    });
  };

  const searchClick = () => {
    doSearch();
  };

  const resetClick = () => {
    const bu = searchParams.bu;
    Object.assign(searchParams, {
      bu: defaultBu,
      brand: 'All',
      region: undefined,
      year: moment().format('YYYY'),
      qm: [moment().format('M')],
      dealerCodeCofico: '',
      dealerCodeSWT: '',
      dealerName: '',
      dealerGroup: ''
    });
    if (bu !== defaultBu) {
      handleBuChange();
    }
  };

  const tabChange = () => {
    doSearch();
  };

  const openYear = ref(false);
  const handlerOpenChange = (status: boolean): void => {
    openYear.value = status;
  };
  const handlerPanelChange = (value: string): void => {
    const time = moment(value).format('YYYY');
    searchParams.year = time as string;
    openYear.value = false;
    if (value) {
      qmDis.value = false;
    } else {
      qmDis.value = true;
    }
  };

  watch(
    () => searchParams.year,
    () => {
      // selectYear.value = !!searchParams.year;
      console.log(410, searchParams.year);
      if (!searchParams.year) {
        searchParams.qm = [];
        qmDis.value = true;
      } else {
        qmDis.value = false;
      }
    }
  );
  const chartVisible = ref(false);
  const openChart = () => {
    chartVisible.value = true;
  };
  const closeChart = () => {
    chartVisible.value = false;
  };

  const downloadColumnsData = [{ title: '文件', dataIndex: 'file' }];
  const downloadTableData = [
    {
      file: '零售提交及校验情况报表',
      fileType: 'form'
    },
    {
      file: '零售提交及校验情况报表源数据',
      fileType: 'detail'
    }
  ];
  const downloadRowSelection = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: any[];
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
      downloadRowSelection.selectedRowKeys = selectedRowKeys;
      downloadRowSelection.selectedRowData = selectedRows;
    },
    selectedRowData: [],
    selectedRowKeys: []
  });
  const downloadVisible = ref(false);
  const openDownload = () => {
    downloadVisible.value = true;
  };
  const closeDownload = () => {
    downloadVisible.value = false;
    downloadRowSelection.selectedRowData = [];
    downloadRowSelection.selectedRowKeys = [];
  };
  const downloadClick = () => {
    if (downloadRowSelection.selectedRowKeys.length < 1) {
      message.warning('请先选择数据');
      return;
    }
    const { q, m } = getSelectQm();
    const buId = getSelectBuId();
    let type;
    if (downloadRowSelection.selectedRowKeys.length > 1) {
      type = 'zip';
    } else {
      type = downloadRowSelection.selectedRowKeys[0];
    }
    const params = {
      url: '/rvapi/report/download/submission',
      method: 'get',
      data: {},
      params: {
        month: m,
        bu: searchParams.bu,
        buId,
        brand: searchParams.brand,
        region: searchParams.region,
        year: searchParams.year,
        quarters: q,
        cofico: searchParams.dealerCodeCofico,
        swt: searchParams.dealerCodeSWT,
        cn: searchParams.dealerName,
        group: searchParams.dealerGroup,
        downFlag: `${type}cn`,
        dealerCode: isRegion ? null : searchParams.dealerCode,
        dealerCodes: isRegion ? null : currentDealerCodes
        // downFlag: 'formcn'
      }
    };
    downloadFile(params, '').then((_: any) => {
      downloadVisible.value = false;
    });
  };

  const initValue = (value: string) => {
    searchParams.bu = value;
    defaultBu = value;
  };

  const route = useRoute();
  const routeInit = () => {
    //TODO brand 为 all的时候该咋办？
    const query = route.query;
    if (Object.keys(query).length > 0) {
      searchParams.bu = route.query.bu as string;
      searchParams.brand = route.query.brand as string;
      searchParams.year = route.query.year as string;
      searchParams.qm = [`Q${route.query.quarter as string}`];
    }
  };

  onMounted(async () => {
    await judgeUser();
    getBuList();
  });
  // const init = () => {
  //     searchParams.brand = route.query.brand as string;
  //     searchParams.year = moment().format('YYYY');
  //     searchParams.qm = [moment().format('M')];
  //     const buList = select.value.buList;
  //     if (route.query.bu) {
  //         const bu = buList.find(
  //             (item: any) => item.id + '' === route.query.bu
  //         );
  //         searchParams.bu = bu.nameEn;
  //     }
  //     doSearch();
  // };
  // onMounted(() => {
  //     init();
  // });
  const router = useRouter();
  const routerJump = () => {
    router.push({ path: '/retailValidation' });
  };

  return {
    isDealer,
    qmDis,
    regionOnly,
    getSelectBuId,
    searchParams,
    qmOptions,
    activeTab,
    tableData,
    columns,
    searchClick,
    resetClick,
    downloadClick,
    tabChange,
    openYear,
    handlerOpenChange,
    handlerPanelChange,
    chartVisible,
    openChart,
    closeChart,
    downloadVisible,
    openDownload,
    closeDownload,
    downloadColumnsData,
    downloadTableData,
    downloadRowSelection,
    select,
    initValue,
    brandList,
    regionList,
    handleBuChange,
    buList,
    getSelectQm,
    routerJump,
    isEmpty
  };
};
export default useOEMPrepaymentQualified;
