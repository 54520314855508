
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import * as echarts from 'echarts';
import useRVSubmission from '@/hooks/SalesReporting/RVSubmission/Dealer/useRVSubmission';
import { getDealerByGroupId, queryHistogram } from '@/API/salesReporting';
import { useStore } from 'vuex';

export default defineComponent({
    props: {
        modalVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        params: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    emits: ['close-modal'],
    setup(props, { emit }) {
        const { getSelectQm, getSelectBuId } = useRVSubmission();
        const store = useStore();
        const currentDealerCodes: string[] = [];
        const judgeUser = (): Promise<any> => {
            const isDealerGroup =
                store.state.user.legalEntity.entityCode === 'Dealer Group';
            if (isDealerGroup) {
                const groupId = store.state.user.orgid;
                return getDealerByGroupId(groupId).then((res: any) => {
                    res.forEach((ele: any) => {
                        currentDealerCodes.push(ele.dealerInfo.dealerCode);
                    });
                });
            } else {
                return Promise.resolve().then(() => {
                    currentDealerCodes.push(
                        store.state.user.organization.entityCode
                    );
                });
            }
        };
        const { state } = useStore();
        const total: any[] = [];
        const eligibleRetail: any[] = [];
        const eligibleRetailPassingRate: any[] = [];
        const ineligibleRetail: any[] = [];
        const getShowData = () => {
            const { q, m } = getSelectQm();
            // const buId = getSelectBuId();
            let buId = '';

            const { bu, brand, region, year } = props.params;
            console.log(68, props.params);
            state.user.buList.forEach((bui: any, buind: any) => {
                if (bui.nameEn === bu) {
                    buId = bui.id;
                }
            });
            const params = {
                month: m,
                bu,
                buId,
                brand,
                region,
                year,
                quarters: q,
                dealerCode: '',
                dealerCodes: [] as string[],
            };
            if (store.state.user.legalEntity.entityCode === 'Dealer') {
                params.dealerCode = store.state.user.organization.entityCode;
                params.dealerCodes = [];
            } else if (store.state.user.organization.entityCode === 'Region') {
                params.dealerCode = '';
                params.dealerCodes = [];
            } else {
                params.dealerCode = '';
                params.dealerCodes = currentDealerCodes;
            }
            return queryHistogram(params).then((res: any) => {
                res.forEach((element: any) => {
                    total.push(element.total);
                    ineligibleRetail.push(element.ineligible);
                    eligibleRetail.push(element.eligibleRetailNo);
                    eligibleRetailPassingRate.push(
                        element.eligibleRetailPassingRate.replace('%', '')
                    );
                });
            });
        };

        const closeModal = () => {
            total.length = 0;
            ineligibleRetail.length = 0;
            eligibleRetail.length = 0;
            eligibleRetailPassingRate.length = 0;
            emit('close-modal');
        };

        const chartRef = ref<HTMLElement>();
        const myChart = ref<any>();

        const showChart = () => {
            if (chartRef.value) {
                console.log(109, ineligibleRetail);
                console.log(110, eligibleRetail);
                console.log(111, eligibleRetailPassingRate);
                // 绘制图表
                myChart.value = echarts.init(chartRef.value);
                myChart.value.setOption({
                    xAxis: {
                        type: 'category',
                        data: [
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                        ],
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: [
                        {
                            type: 'value',
                            show: false,
                        },
                        {
                            type: 'value',
                            show: false,
                            min: 0,
                            max: 100,
                        },
                    ],
                    legend: {
                        x: 'right',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    label: {
                        show: true,
                        position: 'top',
                    },
                    series: [
                        {
                            name: '非有效零售数量',
                            data: ineligibleRetail,
                            type: 'bar',
                            zlevel: 1,
                            barWidth: 20,
                            color: '#00ADEF',
                            label: {
                                color: '#0088C6',
                            },
                        },
                        {
                            name: '有效零售数量',
                            data: eligibleRetail,
                            type: 'bar',
                            barGap: '-100%',
                            barWidth: 20,
                            color: '#7FD6F7',
                            label: {
                                color: '#7FD6F7',
                                formatter: (params: any) => {
                                    return total[params.dataIndex];
                                },
                            },
                        },
                        {
                            name: '零售校验通过率',
                            data: eligibleRetailPassingRate,
                            type: 'line',
                            yAxisIndex: 1,
                            color: '#FFBA3E',
                            label: {
                                color: '#FFBA3E',
                                formatter: '{c}%',
                            },
                        },
                    ],
                });
            }
        };

        onMounted(() => {
            judgeUser();
        });
        watch(
            () => props.modalVisible,
            (val) => {
                if (val) {
                    getShowData().then(() => {
                        nextTick(showChart);
                    });
                }
            }
        );

        return {
            closeModal,
            chartRef,
        };
    },
});
